<template>
  <v-card
    flat
    class="hour-picker"
    :class="{
      background: !isAdmin,
      'is-admin': isAdmin,
    }"
  >
    <div
      v-if="loadingHours"
      class="calendar-overlay d-flex align-center justify-center"
    >
      <EvercamLoadingAnimation size="FiveXl" />
    </div>
    <v-card-title class="justify-center font-weight-bold subtitle-1 pb-0">
      <p class="text-center mb-0">Hours</p>
    </v-card-title>
    <v-card-text class="pa-1 px-1 pr-3">
      <table class="custom-table">
        <tbody>
          <tr v-for="(hours, index) in calendar" :key="index">
            <td v-for="item in hours" :key="item">
              <v-btn
                x-small
                fab
                elevation="0"
                :class="getHourItemClass(item)"
                class="font-weight-medium shadow-none"
                @click="onHourSelection(item)"
              >
                {{ item }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
export default {
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    allowedHours: {
      type: Array,
      default: () => [],
    },
    date: {
      type: String,
      default: "",
    },
    timePicker: {
      type: String,
      default: "",
    },
    loadingHours: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    events: {
      type: [Array],
      default: () => [],
    },
    eventColor: {
      type: String,
      default: "#428dd7AA",
    },
  },
  data() {
    return {
      time: null,
      calendar: [
        [0, 1, 2, 3, 4, 5, 6],
        [7, 8, 9, 10, 11, 12, 13],
        [14, 15, 16, 17, 18, 19, 20],
        [21, 22, 23],
      ],
    }
  },
  computed: {
    buttonActive() {
      if (this.time) {
        return parseInt(this.time?.split(":")[0])
      } else {
        return ""
      }
    },
    isAdmin() {
      return this.$config.public.appName === "admin"
    },
  },
  watch: {
    timePicker() {
      this.time = this.timePicker
    },
  },
  created() {
    this.time = this.timePicker
  },
  methods: {
    changeHour() {
      this.$emit("input", this.time)
      this.$emit("change", this.time)
    },
    formatNumToTime(n) {
      return this.$moment(n, "HH").format("HH:mm")
    },
    getHourItemClass(item) {
      return {
        active2: this.buttonActive == item && this.allowedHours.length,
        inactive: this.buttonActive != item,
        disabled: this.allowedHours.find((v) => v == item) === undefined,
        "hour-item--has-event": this.events.find((e) => e == item),
      }
    },
    onHourSelection(item) {
      this.isLoading = true
      this.time = this.formatNumToTime(item)
      this.changeHour()
    },
  },
}
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_colors";
@import "~vuetify/src/styles/settings/_light";
@import "~vuetify/src/styles/settings/_dark";

.hour-picker:not(.is-admin) {
  .active2 {
    background-color: var(--v-primary-base) !important;
    color: var(--v-on_primary-base);
  }
  .active2.disabled {
    background-color: var(--v-primary-base) !important;
    color: var(--v-on_primary-base) !important;
  }
  .v-btn--active.v-btn--disabled > div {
    color: var(--v-on_primary-base) !important;
  }

  .active2 > span {
    color: white;
  }

  .inactive {
    background-color: var(--v-background-base) !important;
  }

  .disabled {
    box-shadow: none;
    pointer-events: none;
    color: map-deep-get($material-light, "buttons", "disabled") !important;
  }
  .disabled.theme--dark {
    color: map-deep-get($material-dark, "buttons", "disabled") !important;
  }
}

.custom-table {
  width: 100%;
}

.shadow-none {
  box-shadow: none !important;
}

.calendar-overlay {
  position: absolute;
  z-index: 400;
  top: 0px;
  left: 0px;
  background-color: var(--v-background-base);
  height: 100%;
  width: 100%;
  opacity: 0.9;
}

.hour-item--has-event:after {
  content: "";
  background-color: rgba(66, 141, 215, 0.667);
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: block;
  position: absolute;
  bottom: 2px;
  left: 1px;
  right: 0;
  margin: auto;
}
</style>
