<template>
  <div>
    <ECol cols="12" class="py-0">
      <ERow>
        <ECol
          ><v-text-field
            v-model="selectedLayer.id"
            label="Layer ID"
            outlined
            dense
            disabled
          />
          <v-text-field
            v-model="selectedLayer.displayName"
            label="Display Name"
            outlined
            dense
          />
          <v-textarea
            v-model="shapes"
            label="Layer Data"
            outlined
            dense
            hide-details
          />
          <p v-show="shapesError" class="error--text pl-4 py-2">
            Error parsing the shapes json
          </p>
          <v-text-field
            v-model="selectedLayer.name"
            label="Layer Name"
            outlined
            dense
            hide-details
            class="my-4"
          />
          <v-select
            v-model="selectedLayer.layerType"
            :items="modelTypes"
            label="Layer type"
            return-object
            placeholder="Layer Type"
            dense
            class="my-4"
            hide-details
            outlined
          />
          <ERow justify="end" class="mt-3 mr-n2">
            <v-btn
              color="primary"
              class="mx-2"
              :disabled="!selectedLayer.id"
              :loading="isLayerUpdating"
              @click="updateLayer"
              >Update Layer</v-btn
            >
            <v-btn
              color="error"
              class="mx-2"
              :disabled="!selectedLayer.id"
              @click="openDeleteDialog"
            >
              Delete Layer
            </v-btn>
          </ERow>
        </ECol>
        <div class="d-flex">
          <div class="shrink">
            <v-divider vertical></v-divider>
          </div>
          <div class="grow">
            <div class="pl-2">Start At</div>
            <v-date-picker
              v-model="selectedLayer.startAt"
              class="mr-3 mb-3"
              placeholder="Start At"
              color="primary"
              hide-details
              dense
              no-title
            />
            <v-divider />
            <HourPicker
              :time-picker="selectedHour"
              :allowed-hours="allowedHours"
              :date="selectedLayer.startAt"
              color="primary"
              @change="changeHour"
            />
          </div>
        </div>
      </ERow>
      <v-divider class="mb-4"></v-divider>
      <v-data-table
        v-if="cameraDialogStore.camera"
        v-model="selected"
        :headers="headers"
        :items="items"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="admin-data-table elevation-1 overflow-y-hidden"
        dense
        must-sort
        :loading="loading"
        loading-text="Loading..."
        calculate-widths
        :mobile-breakpoint="0"
        :options.sync="options"
        item-key="id"
        :height="tableHeight"
        fixed-header
        :footer-props="{
          'items-per-page-options': [50, 100, 500],
        }"
        :hide-default-footer="items && items.length <= 50"
        @click:row="layerSelected"
      >
        <template #footer>
          <ERow no-gutters justify="end"
            ><v-btn text color="primary" @click="fetchLayers">
              Refresh <v-icon right>fa-sync</v-icon></v-btn
            ></ERow
          >
        </template>
      </v-data-table>
    </ECol>
  </div>
</template>

<script>
import LayersTableHeader from "@/components/layers/layersTableHeaders"
import { BimModelType } from "@evercam/shared/types/bim"
import LAYER_TYPES from "@evercam/shared/constants/layerTypes"
import HourPicker from "@evercam/shared/components/HourPicker"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default {
  components: { HourPicker },
  data() {
    return {
      selectedRow: null,
      shapesError: false,
      selectedLayer: {
        id: null,
        name: null,
        insertedAt: null,
        updatedAt: null,
        startAt: null,
        shapes: {
          model: null,
        },
      },
      modelTypes: [
        ...Object.values(BimModelType).map(
          (value) => `bim_${value.toLowerCase()}`
        ),
        LAYER_TYPES.BIM_MASK,
      ],
      selected: [],
      items: [],
      headers: LayersTableHeader,
      options: {},
      total: 0,
      loading: true,
      sortBy: "id",
      sortDesc: true,
      currentCamera: null,
      selectedHour: null,
      isLayerUpdating: false,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore),
    allowedHours() {
      return Array.from({ length: 24 }, (_, i) => `${i}`)
    },
    shapes: {
      get() {
        return JSON.stringify(this.selectedLayer.shapes)
      },
      set(newValue) {
        try {
          this.selectedLayer.shapes = JSON.parse(newValue)
          this.shapesError = false
        } catch (e) {
          this.shapesError = true
        }
      },
    },
  },
  mounted() {
    this.currentCamera = this.cameraDialogStore.camera.exid
    this.$nextTick(this.fetchLayers)
  },
  methods: {
    async fetchLayers() {
      if (!this.cameraDialogStore.camera?.exid) {
        await this.cameraDialogStore.selectCamera(this.$route.params.exid)
        if (!this.cameraDialogStore.camera?.exid) return
      }
      this.shapesError = false
      this.selectedLayer = {
        id: null,
        name: null,
        insertedAt: null,
        updatedAt: null,
        startAt: null,
        shapes: {
          model: null,
        },
      }
      try {
        this.loading = true
        const response = await EvercamApi.layers.getLayers(
          this.cameraDialogStore.camera?.exid,
          {
            apiKey: this.cameraDialogStore.camera?.userApiKey,
            apiId: this.cameraDialogStore.camera?.userApiId,
          }
        )
        const filteredResponse = response.filter(
          (layer) => layer.layerType !== LAYER_TYPES.MILESTONE
        )
        this.total = filteredResponse.length
        this.items = filteredResponse
      } catch (error) {
        this.$notifications.error({
          text: "Could not load Layers!",
          error,
        })
      } finally {
        this.loading = false
      }
    },
    async layerSelected(layer, row) {
      try {
        if (this.selectedRow) {
          this.selectedRow.select(false)
        }
        this.shapesError = false
        row.select(true)
        this.selectedRow = row
        const data = await EvercamApi.layers.getLayersById(
          this.cameraDialogStore.camera?.exid,
          layer.id,
          {
            apiKey: this.cameraDialogStore.camera?.userApiKey,
            apiId: this.cameraDialogStore.camera?.userApiId,
          }
        )
        this.selectedHour = new Date(data.startAt).toISOString().substr(11, 2)
        data.startAt = new Date(data.startAt).toISOString().substr(0, 10)
        try {
          data.shapes = JSON.parse(data.shapes)
        } catch (_e) {
          data.shapes = {}
        }
        this.selectedLayer = data
      } catch (error) {
        this.$notifications.error({
          text: "Could not load Layers!",
          error,
        })
      }
    },
    async openDeleteDialog() {
      if (
        await this.$confirmDialog.open({
          title: "Delete layer",
          message: `Are you sure you want to delete layer id :<span class='font-weight-bold'>${this.selectedLayer.id}</span>?`,
        })
      ) {
        this.deleteLayer()
      }
    },
    async deleteLayer() {
      try {
        await EvercamApi.layers.deleteLayer(
          this.cameraDialogStore.camera?.exid,
          this.selectedLayer.id,
          {
            apiKey: this.cameraDialogStore.camera?.userApiKey,
            apiId: this.cameraDialogStore.camera?.userApiId,
          }
        )
        this.selectedLayer = {
          id: null,
          name: null,
          insertedAt: null,
          updatedAt: null,
          startAt: null,
          shapes: {
            model: null,
          },
        }
        this.$notifications.success("Layer deleted successfully.")
        this.fetchLayers()
      } catch (error) {
        this.$notifications.error({ text: "Could not delete Layer!", error })
      }
    },
    updateLayer() {
      var timestamp = this.selectedLayer.startAt + "T" + this.selectedHour
      timestamp = this.$moment.tz(timestamp, "GMT").toISOString()
      if (this.shapesError) {
        this.$notifications.warn("Please provide a correct shape.")

        return
      }
      this.isLayerUpdating = true
      EvercamApi.layers
        .updateLayer(
          this.cameraDialogStore.camera.exid,
          this.selectedLayer.id,
          {
            name: this.selectedLayer.name,
            startAt: timestamp,
            layerType: this.selectedLayer.layerType,
            displayName: this.selectedLayer.displayName,
            shapes: JSON.stringify(this.selectedLayer.shapes),
            apiKey: this.cameraDialogStore.camera?.userApiKey,
            apiId: this.cameraDialogStore.camera?.userApiId,
          }
        )
        .then(() => {
          this.$notifications.success("BIM model updated successfully.")
          this.fetchLayers()
        })
        .catch((error) => {
          this.$notifications.error({
            text: "Could not update BIM model!",
            error,
          })
        })
        .finally(() => {
          this.isLayerUpdating = false
        })
    },
    changeHour(hour) {
      this.selectedHour = hour
    },
  },
}
</script>

<style scoped>
.admin-data-table /deep/ .v-data-table__selected {
  background: #74b4f5 !important;
}
</style>
