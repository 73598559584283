<template>
  <v-container fluid class="pa-0">
    <LayerTabComponent />
  </v-container>
</template>

<script>
import LayerTabComponent from "@/components/bim/layerTabs/LayerTabComponent"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
export default {
  components: {
    LayerTabComponent,
  },
  asyncData({ params }) {
    useBreadcrumbStore().breadcrumbs = [
      {
        icon: "fa-solid fa-home",
        href: "/",
      },
      {
        name: "Bim",
        href: "/bim-compare",
      },
      {
        name: params.exid,
        href: `/bim-compare/${params.exid}/layers`,
      },
      {
        name: "layers",
      },
    ]
  },
}
</script>
