export default [
  {
    value: "name",
    text: "Layer Name",
    visible: true,
    width: 190,
  },
  {
    value: "id",
    text: "Layer ID",
    visible: true,
    width: 150,
  },
  {
    value: "layerType",
    text: "Layer Type",
    width: 200,
    visible: true,
  },
  {
    value: "startAt",
    text: "Start At",
    width: 200,
    visible: true,
  },
  {
    value: "insertedAt",
    text: "Inserted At",
    width: 170,
    visible: true,
  },
  {
    value: "updatedAt",
    text: "Updated At",
    width: 200,
    visible: true,
  },
]
